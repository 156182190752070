import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import 'slick-carousel/slick/slick-theme.css';
import "./sass/style.scss";
import 'viewport-extra';
import simpleParallax from 'simple-parallax-js';

AOS.init({
  offset: 50,
  duration: 1000,
  easing: 'linear',
  once: true,
});

$(function(){

  $('.nav-toggle').on('click', function () {
    $('.gNav_toggle').toggleClass('active');
    $('.gNav_overlay').toggleClass('open');
    $('.siteHead').toggleClass('visible');
    if ($("body").css('overflow') == 'hidden') {
      $("body").css({ overflow: 'visible' });
    } else if ($("body").css('overflow') == 'visible') {
      $("body").css({ overflow: 'hidden' });
    };
  });



  $('.firstView_slider').on('init', function(){
    $('.slick-slide[data-slick-index="0"]').addClass('zooming');
  })
  $('.firstView_slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    pauseOnHover: false,
    infinite: true,
    arrows: false,
    dots: false,
    fade: true
  })
    .on({
      beforeChange: function(event, slick, currentSlide, nextSlide) {
        $(".slick-slide", this).eq(currentSlide).addClass("preve-slide");
        $(".slick-slide", this).eq(nextSlide).addClass("zooming");
      },
      afterChange: function(event, slick, currentSlide) {
        $(".preve-slide", this).removeClass("preve-slide zooming");
      }
    });

  $('.firstView_slider').on('init', function(){
    $('.slick-slide[data-slick-index="0"]').addClass('zooming');
  });

  $('.ynymGallery_slider-top').slick({
    autoplay: true, //自動でスクロール
    autoplaySpeed: 0, //自動再生のスライド切り替えまでの時間を設定
    speed: 15000, //スライドが流れる速度を設定
    cssEase: "linear", //スライドの流れ方を等速に設定
    slidesToShow: 5, //表示するスライドの数
    swipe: false, // 操作による切り替えはさせない
    arrows: false, //矢印非表示
    pauseOnFocus: false, //スライダーをフォーカスした時にスライドを停止させるか
    pauseOnHover: false, //スライダーにマウスホバーした時にスライドを停止させるか
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4, //画面幅750px以下でスライド3枚表示
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3, //画面幅750px以下でスライド3枚表示
        }
      },
    ]
  });

  $('.ynymGallery_slider-bottom').slick({
    rtl: true,
    autoplay: true, //自動でスクロール
    autoplaySpeed: 0, //自動再生のスライド切り替えまでの時間を設定
    speed: 15000, //スライドが流れる速度を設定
    cssEase: "linear", //スライドの流れ方を等速に設定
    slidesToShow: 5, //表示するスライドの数
    swipe: false, // 操作による切り替えはさせない
    arrows: false, //矢印非表示
    pauseOnFocus: false, //スライダーをフォーカスした時にスライドを停止させるか
    pauseOnHover: false, //スライダーにマウスホバーした時にスライドを停止させるか
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4, //画面幅750px以下でスライド3枚表示
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3, //画面幅750px以下でスライド3枚表示
        }
      },
    ]
  });

});




/* ----------------------------------
ローディング
---------------------------------- */

const loading = document.getElementById('loading');
/* 読み込み完了 */
window.addEventListener('load', stopload);

/* 6秒経ったら強制的にロード画面を非表示にする */
setTimeout(stopload,10000, '');

/* ロード画面を非表示にする処理 */
function stopload(){
  loading.classList.add('is-loaded');

  const images = document.querySelectorAll('.parallax');
  new simpleParallax(images, {
    delay: .6,
    transition: 'cubic-bezier(0,0,0,1)'
  });
}

/* ----------------------------------
スムーススクロール
---------------------------------- */
document.addEventListener("click", e => {
  const target = e.target;
  // clickした要素がclass属性、smooth-scrollを含まない場合は処理を中断
  if (!target.classList.contains("smoothscroll")) return;
  e.preventDefault();
  const targetId = target.hash;
  const targetElement = document.querySelector(targetId);
  // 画面上部から要素までの距離
  const rectTop = targetElement.getBoundingClientRect().top;
  // 現在のスクロール距離
  const offsetTop = window.pageYOffset;
  // スクロール位置に持たせるバッファ
  const buffer = 100;
  const top = rectTop + offsetTop - buffer;
  console.log(top);

  window.scrollTo({
    top,
    behavior: "smooth"
  });
});

/* ----------------------------------
画面高さをCSS変数に代入（100vh用）
---------------------------------- */

const setFillHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

let vw = window.innerWidth;

window.addEventListener('resize', () => {
  if (vw === window.innerWidth) {
    // 画面の横幅にサイズ変動がないので処理を終える
    return;
  }

  // 画面の横幅のサイズ変動があった時のみ高さを再計算する
  vw = window.innerWidth;
  setFillHeight();
});

// 初期化
setFillHeight();

/* ----------------------------------
ヘッダー動作
---------------------------------- */
const header = document.querySelector('.siteHead');
if(document.querySelector('.firstView') ) {
  const headHiddenOptions = {
    root: null, // 今回はビューポートをルート要素とする
    rootMargin: "", // ビューポートの中心を判定基準にする
    threshold: 0 // 閾値は0
  };
  const fvObserver = new IntersectionObserver(doHidden, headHiddenOptions);
  fvObserver.observe(document.querySelector('.firstView'));
  function doHidden(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        header.classList.add('is-front');
      }else{
        header.classList.remove('is-front');
      }
    });
  }
};

if(document.querySelector('.page_titlearea') ) {
  const headHiddenOptions = {
    root: null, // 今回はビューポートをルート要素とする
    rootMargin: "", // ビューポートの中心を判定基準にする
    threshold: 0 // 閾値は0
  };
  const fvObserver = new IntersectionObserver(doHidden, headHiddenOptions);
  fvObserver.observe(document.querySelector('.page_titlearea'));
  function doHidden(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        header.classList.add('is-front');
      }else{
        header.classList.remove('is-front');
      }
    });
  }
}

if(document.querySelector('.interview_spacer') ) {
  const headHiddenOptions = {
    root: null, // 今回はビューポートをルート要素とする
    rootMargin: "", // ビューポートの中心を判定基準にする
    threshold: 0 // 閾値は0
  };
  const fvObserver = new IntersectionObserver(doHidden, headHiddenOptions);
  fvObserver.observe(document.querySelector('.interview_spacer'));
  function doHidden(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        header.classList.add('is-front');
      }else{
        header.classList.remove('is-front');
      }
    });
  }
}
